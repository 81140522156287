  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:100'" name="Name" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>


      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Address</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-textarea 
            name="Address"
            rows="3" 
            v-model="form.address"
            v-validate="'max:500'"
            class="w-full mb-0"/>
          <span class="text-danger text-sm">{{ errors.first('Address') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Contact Details</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.contactDetails" v-validate="'max:500'" name="Contact Details" />
          <span class="text-danger text-sm">{{ errors.first('Contact Details') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Opening Times</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.openingTimes" v-validate="'max:500'" name="Opening Times" />
          <span class="text-danger text-sm">{{ errors.first('Opening Times') }}</span>
        </div>
      </div>


      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <vs-switch class="" v-model="form.isActive" />
        </div>
      </div>

      <div class="flex flex-wrap justify-end">
          <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
          <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>
    </vx-card>

  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
  },
  props: {
    applicationId: { required: true },
    branchId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        name: '',
        contactDetails: '',
        address: '',
        openingTimes: '',
        isActive: true,
      }),

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.branchId == 0 ? 'Add Branch' : 'Edit Branch'
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('currencyexchange')) {
      this.$router.push('/error-404')
    }
    
    
    try {
      if (this.branchId) {
        this.$vs.loading()
        const branch = await this.$http.get(`api/currencyExchanges/apps/${this.applicationId}/branches/${this.branchId}`)
        this.form = new Form({...branch.data})
      }
      
    } catch (error) {
      this.$_notifyFailure(error)
    } 
    this.$vs.loading.close();


  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      if (!this.branchId) {
        try {
          await this.$http.post(`api/currencyExchanges/apps/${this.applicationId}/branches`, this.form.data())
          this.$_notifySuccess('Successfully added branch');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      } else {
        try {
          await this.$http.put(`api/currencyExchanges/apps/${this.applicationId}/branches`, this.form.data())
          this.$_notifySuccess('Successfully updated branch');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      }
      
      this.$vs.loading.close();
      this.onCancel()

      
    },
    onCancel () {
      this.$router.push({name: 'application-currency-exchange-branches', 
        params: { applicationId: this.applicationId }
      })
    },
  }
}
</script>

<style>

</style>
